<script>
    import WalletNav from './WalletNav.svelte';

    export let limitedEditionPost;
    export let errors = [];
</script>

<div class="col-12 min-vh-100 border-right pr-0">
    <div class="border-bottom p-4">
        <svelte:component this="{WalletNav}" />
        {#if limitedEditionPost.length > 0}
            {#each limitedEditionPost as item}
                <div class="col-md-4 col-sm-12">
                    <div class="card" style="width: 18rem;">
                        <img class="card-img-top" src="..." alt="Card image cap" />
                        <div class="card-body">
                            <a href=""> <h5 class="card-title">{item.text}</h5> </a>
                            <span>Price <b> {item.price}</b></span> <br />
                            <span>supply left <b> {item.supply_limit}</b></span>

                            <p class="card-text">post description</p>
                            <a href=""> <button class="btn btn-primary itemPurchaseButton">view</button> </a>
                        </div>
                    </div>
                </div>
            {/each}
        {:else}
            <div class="rounds-container m-3 !mt-8">
                <div class="py-4 text-center">
                    <h2 class="text-2xl font-extrabold">No Items in your Wallet</h2>
                    <p>
                        Currently, you don't have any items yet in your wallet. Go look around the <a
                            class="!underline"
                            href="/markets">marketplace</a
                        > to get ahold of some.
                    </p>
                </div>
            </div>
        {/if}
        <slot></slot>
    </div>
</div>
<!-- Use the errors prop somewhere in your component -->
{#if errors.length}
    <ul>
        {#each errors as error}
            <li>{error}</li>
        {/each}
    </ul>
{/if}
